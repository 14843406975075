import { Modal } from "antd";
import React from "react";
import cardimg from "../../assets/Images/cardimg.png";

const Viewlist = (props) => {
  const { show, handlecancel, previewImage, viewTemplate, isPromotionview,isPromotion } =
    props;

  return (
    <>
      <Modal
        centered
        title="Basic Modal"
        visible={show}
        wrapClassName={
          isPromotion  || isPromotionview? "viewModal viewModalPromotion" : "viewModal"
        }
        onCancel={() => {
          handlecancel();
        }}
      >
        <div className="cardImgContainer">
          <div className="firstTextBox">
            {isPromotion || isPromotionview ? (
              ""
            ) : (
              <span className="cardText cardTextFirst">
                Dear Title. First Name Second Name
              </span>
            )}
          </div>

          <div className="cardImgBody">
            <img
              src={
                viewTemplate
                  ? viewTemplate
                  : previewImage
                  ? previewImage
                  : cardimg
              }
              alt="cardImg"
              className="cardImg"
            />
          </div>
          <div className="lastTextBox">
            {isPromotion  || isPromotionview? (
              ""
            ) : (
              <span className="cardText cardLastText">From: Commercial Bank, Branch/Department/Sender Name</span>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Viewlist;
