import { combineReducers } from 'redux';
import templateFormReducer from '../containers/templates/reducer/formReducer';
import viewTemplateReducer from '../containers/templates/reducer/viewTemplateReducer';
import loginReducer  from '../containers/user/reducer/loginReducer';
const rootReducer =  combineReducers({
    loginReducer,
    templateFormReducer,
    viewTemplateReducer
})

export default rootReducer;