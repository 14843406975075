import React, { useEffect, useState } from "react";
import { Input, Row, Col, Button } from "antd";
import moment from "moment";
import { DatePicker } from "antd";
import Imageuploadtemplate from "./imageUploadTemplate";
import { useNavigate } from "react-router-dom";
import { GREETING, PROMOTION } from "../../constants/constants";

const dateFormatList = ["YYYY-MM-DD", "DD/MM/YYYY"];

const Commantemplate = (props) => {
  const { handleSubmit, templateData, isPromotion, isgreeting } = props;
  // const previousDate = moment().subtract(1, "days").format(dateFormatList[0]);
  const currentDate = moment().format(dateFormatList[0]);
  const [form, setForm] = useState({
    templateName: "",
    startDate: currentDate,
    subjectLine: "",
    endDate: currentDate,
    templateImg: "",
  });
  let navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const objectSize = Object.keys(templateData).length;

  useEffect(() => {
    if (objectSize) {
      setForm((prev) => {
        return {
          ...prev,
          templateName: templateData ? templateData?.templateName : "",
          startDate: templateData ? templateData?.startDate : currentDate,
          subjectLine: templateData ? templateData?.subjectLine : "",
          endDate: templateData ? templateData?.endDate : currentDate,
          templateImg: templateData ? templateData?.uploadImg : "",
        };
      });
    }
  }, [templateData]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      handleSubmit(form);
    }
  }, [errors]);

  const handleForm = (e) => {
    e.preventDefault();
    setErrors(validation(form));
    setIsSubmit(true);
  };

  const handleUploadValidation = (value) => {
    if (value) {
      setForm((prevForm) => ({
        ...prevForm,
        templateImg: "",
      }));
    }
  };
  useEffect(() => {
    handleValidation();
  }, [form]);

  const patternValidation = (values) => {
    return /^\s/.test(values);
  };
  const handleChange = (e) => {
    const values = e.target.value;
    const validate = patternValidation(values);

    if (validate) return null;
    else setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeDate = (dateString, type) => {
    if (type === "startDate")
      setForm((pre) => {
        return { ...pre, startDate: dateString };
      });

    if (type === "endDate")
      setForm((pre) => {
        return { ...pre, endDate: dateString };
      });
  };

  const validation = (values) => {
    let errors = {};
    if (!values.templateName) {
      errors.templateName = "Please enter template name";
    } else if (values.templateName.length > 50) {
      errors.templateName = "Template name size is exceeded";
    }
    if (!values.subjectLine) {
      errors.subjectLine = "Please enter subject line";
    } else if (values.subjectLine.length > 50) {
      errors.subjectLine = "Subject line size is exceeded";
    }
    if (!values.startDate) {
      errors.startDate = "Please enter start date";
    }
    if (!values.endDate) {
      errors.endDate = "Please enter end date";
    }
    if (!values.templateImg) {
      errors.templateImg = "Please upload a template";
    }

    return errors;
  };
  const handleValidation = () => {
    if (form.templateName.length && form.templateName.length <= 50) {
      setErrors((prev) => {
        return { ...prev, templateName: "" };
      });
    }
    if (form.subjectLine.length && form.subjectLine.length <= 50) {
      setErrors((prev) => {
        return { ...prev, subjectLine: "" };
      });
    }
    if (form.startDate.length) {
      setErrors((prev) => {
        return { ...prev, startDate: "" };
      });
    }
    if (form.endDate.length) {
      setErrors((prev) => {
        return { ...prev, endDate: "" };
      });
    }
    
  };

  const handleCancel = () => {
    navigate(
      `/template/view?param=${
        isgreeting ? "greeting" : "promotion"
      }&status=active`
    );
  };
  return (
    <div>
      <form onSubmit={handleForm}>
        <Row gutter={[30, 0]} className="mainRow">
          <Col className="colForm" xs={24} sm={24} md={24} lg={12} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span className="greetingHeading">
                  {objectSize > 0 ? "Edit" : "Create"}{" "}
                  {isPromotion ? PROMOTION : isgreeting ? GREETING : null}
                </span>
              </Col>
            </Row>
            <div className="templateFormContainer">
              <Row>
                <Col xs={24} sm={4} md={24} lg={24} xl={24}>
                  <div className="formTemplateLeft">
                    <Row gutter={[30, 0]}>
                      <Col xs={24} sm={4} md={24} lg={12} xl={12}>
                        <p className="templateFormLabel">
                          Template Name <sup className="mandatory"> * </sup>
                        </p>
                        <Input
                          className="inputFeildTemplate "
                          name="templateName"
                          onChange={handleChange}
                          value={form?.templateName}
                        />
                        <span className="errorMessage">
                          {errors.templateName}
                        </span>
                      </Col>
                      <Col xs={24} sm={4} md={24} lg={12} xl={12}>
                        <p className="templateFormLabel">
                          Subject Line <sup className="mandatory"> * </sup>
                        </p>
                        <Input
                          className="inputFeildTemplate "
                          name="subjectLine"
                          onChange={handleChange}
                          value={form?.subjectLine}
                        />
                        <span className="errorMessage">
                          {errors.subjectLine}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col xs={24} sm={4} md={24} lg={24} xl={24}>
                  <div className="formTemplateRight">
                    <Row gutter={[30, 0]}>
                      <Col xs={24} sm={4} md={24} lg={12} xl={12}>
                        <p className="templateFormLabel">
                          Start Date <sup className="mandatory"> * </sup>
                        </p>
                        <DatePicker
                          className="inputFeildTemplate"
                          value={
                            form.startDate !== ""
                              ? moment(form?.startDate, dateFormatList[0])
                              : null
                          }
                          format={dateFormatList}
                          disabledDate={(d) =>
                            !d ||
                            d.isAfter("2050-05-03") ||
                            d.isBefore(moment(currentDate, dateFormatList[0]))
                          }
                          name="startDate"
                          onChange={(date, dateString) => {
                            handleChangeDate(dateString, "startDate");
                          }}
                          inputReadOnly={true}
                        />

                        <span className="errorMessage">{errors.startDate}</span>
                      </Col>
                      <Col xs={24} sm={4} md={24} lg={12} xl={12}>
                        <p className="templateFormLabel">
                          End Date <sup className="mandatory"> * </sup>
                        </p>
                        <DatePicker
                          className="inputFeildTemplate"
                          value={
                            moment(form.startDate, dateFormatList[0]).isAfter(
                              moment(form.endDate, dateFormatList[0])
                            )
                              ? moment(form?.startDate, dateFormatList[0]) &&
                                setForm((pre) => {
                                  return { ...pre, endDate: form?.startDate };
                                })
                              : form.endDate !== ""
                              ? moment(form?.endDate, dateFormatList[0])
                              : null
                          }
                          format={dateFormatList}
                          disabledDate={(d) =>
                            !d ||
                            d.isAfter("2050-05-03") ||
                            templateData?.endDate
                              ? d.isBefore(
                                  moment(form?.startDate, dateFormatList[0])
                                )
                              : d.isBefore(
                                  moment(form?.startDate, dateFormatList[0])
                                )
                          }
                          name="endDate"
                          onChange={(date, dateString) => {
                            handleChangeDate(dateString, "endDate");
                          }}
                          inputReadOnly={true}
                        />

                        <span className="errorMessage">{errors.endDate}</span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Imageuploadtemplate
              handleForm={setForm}
              uplodedImage={templateData?.uploadImg}
              validation_Message={errors.templateImg}
              isgreeting={isgreeting}
              isPromotion={isPromotion}
              setErrors={setErrors}
              handleUploadValidation={handleUploadValidation}
            />
          </Col>
        </Row>

        <div className="templateBtnSection">
          <Button className="backBtn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="saveBtn" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Commantemplate;
