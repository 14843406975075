import {
  ACTIVATE_TEMPLATES_REQUEST,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESS,
  GET_EDIT_TEMPLATE_IN_PROGRESS,
  GET_EDIT_TEMPLATE_REQUEST,
  GET_EDIT_TEMPLATE_SUCCESS,
  GET_TEMPLATES_FAILED,
  GET_TEMPLATES_IN_PROGRESS,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
} from "../constants";

const getViewTemplate = (payload) => ({
  type: GET_TEMPLATES_REQUEST,
  payload,
});

const getViewTemplateInProgress = () => ({
  type: GET_TEMPLATES_IN_PROGRESS,
});

const getViewTemplateSuccess = (result) => ({
  type: GET_TEMPLATES_SUCCESS,
  result,
});

const getViewTemplateFailed = (error) => ({
  type: GET_TEMPLATES_FAILED,
  error,
});

const getEditTemplate = (payload) => ({
  type: GET_EDIT_TEMPLATE_REQUEST,
  payload,
});

const getEditemplateInProgress = () => ({
  type: GET_EDIT_TEMPLATE_IN_PROGRESS,
});

const getEditTemplateSuccess = (result) => ({
  type: GET_EDIT_TEMPLATE_SUCCESS,
  result,
});

const getEditTemplateFailed = (error) => ({
  type: GET_EDIT_TEMPLATE_SUCCESS,
  error,
});



export {
  getViewTemplate,
  getViewTemplateInProgress,
  getViewTemplateSuccess,
  getViewTemplateFailed,
  getEditTemplate,
  getEditemplateInProgress,
  getEditTemplateSuccess,
  getEditTemplateFailed,
};
