import {
  SAVE_FORM_REQUEST,
  SAVE_FORM_IN_PROGRESS,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILED,
  EDIT_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_IN_PROGRESS,
  EDIT_TEMPLATES_SUCCESS,
  EDIT_TEMPLATES_FAILED,
  CHANAGE_TEMPLATES_STATUS,
  CHANAGE_TEMPLATES_STATUS_IN_PROGRESS,
  CHANAGE_TEMPLATES_STATUS_SUCCESS,
  CHANAGE_TEMPLATES_STATUS_FAILED,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATES_REQUEST,
  DELETE_TEMPLATE_FAILED,
} from "../constants";

const saveForm = (payload) => ({
  type: SAVE_FORM_REQUEST,
  payload,
});

const saveFormInProgress = () => ({
  type: SAVE_FORM_IN_PROGRESS,
});

const saveFormSuccess = (result) => ({
  type: SAVE_FORM_SUCCESS,
  result,
});

const saveFormFailed = (error) => ({
  type: SAVE_FORM_FAILED,
  error,
});

const editTemplateForm = (payload) => ({
  type: EDIT_TEMPLATES_REQUEST,
  payload,
});

const editTemplateFormInProgress = () => ({
  type: EDIT_TEMPLATES_IN_PROGRESS,
});

const editTemplateFormSuccess = (result) => ({
  type: EDIT_TEMPLATES_SUCCESS,
  result,
});

const editTemplateFormFailed = (error) => ({
  type: EDIT_TEMPLATES_FAILED,
  error,
});

const changeTemplateStatus = (payload) => ({
  type: CHANAGE_TEMPLATES_STATUS,
  payload,
});

const changeTemplateStatusInProgress = () => ({
  type: CHANAGE_TEMPLATES_STATUS_IN_PROGRESS,
});

const changeTemplateStatusSuccess = (result) => ({
  type: CHANAGE_TEMPLATES_STATUS_SUCCESS,
  result,
});

const changeTemplateStatusFailed = (error) => ({
  type: CHANAGE_TEMPLATES_STATUS_FAILED,
  error,
});


const deleteTemplate = (payload) => ({
  type: DELETE_TEMPLATES_REQUEST,
  payload,
});

const deleteTemplateInProgress = () => ({
  type: DELETE_TEMPLATE_IN_PROGRESS,
});

const deleteTemplateSuccess = (result) => ({
  type: DELETE_TEMPLATE_SUCCESS,
  result,
});

const deleteTemplateFaild = (result) => ({
  type: DELETE_TEMPLATE_FAILED,
  result,
});

export {
  saveForm,
  saveFormInProgress,
  saveFormSuccess,
  saveFormFailed,
  editTemplateForm,
  editTemplateFormInProgress,
  editTemplateFormSuccess,
  editTemplateFormFailed,
  changeTemplateStatus,
  changeTemplateStatusInProgress,
  changeTemplateStatusSuccess,
  changeTemplateStatusFailed,
  deleteTemplate,
  deleteTemplateInProgress,
  deleteTemplateSuccess,
  deleteTemplateFaild
};
