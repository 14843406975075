const SAVE_FORM_REQUEST = "SAVE_FORM_REQUEST";
const SAVE_FORM_IN_PROGRESS = "SAVE_FORM_IN_PROGRESS";
const SAVE_FORM_SUCCESS = "SAVE_FORM_SUCCESS";
const SAVE_FORM_FAILED = "SAVE_FORM_FAILED";

const GET_TEMPLATES_REQUEST = "GET_TEMPLATES_REQUEST";
const GET_TEMPLATES_IN_PROGRESS = "GET_TEMPLATES_IN_PROGRESS";
const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
const GET_TEMPLATES_FAILED = "GET_TEMPLATESFAILED";

const GET_EDIT_TEMPLATE_REQUEST = "GET_EDIT_TEMPLATE_REQUEST";
const GET_EDIT_TEMPLATE_IN_PROGRESS = "GET_EDIT_TEMPLATE_IN_PROGRESS";
const GET_EDIT_TEMPLATE_SUCCESS = "GET_EDIT_TEMPLATE_SUCCESS";
const GET_EDIT_TEMPLATE_FAILED = "GET_EDIT_TEMPLATE_FAILED";

const EDIT_TEMPLATES_REQUEST = "EDIT_TEMPLATES_REQUEST";
const EDIT_TEMPLATES_IN_PROGRESS = "EDIT_TEMPLATES_IN_PROGRESS";
const EDIT_TEMPLATES_SUCCESS = "EDIT_TEMPLATES_SUCCESS";
const EDIT_TEMPLATES_FAILED = "EDIT_TEMPLATES_FAILED";

const CHANAGE_TEMPLATES_STATUS = "CHANAGE_TEMPLATES_STATUS";
const CHANAGE_TEMPLATES_STATUS_IN_PROGRESS = "CHANAGE_TEMPLATES_STATUS_IN_PROGRESS";
const CHANAGE_TEMPLATES_STATUS_SUCCESS = "CHANAGE_TEMPLATES_STATUS_SUCCESS";
const CHANAGE_TEMPLATES_STATUS_FAILED = "CHANAGE_TEMPLATES_STATUS_FAILED";

const DELETE_TEMPLATES_REQUEST = "DELETE_TEMPLATES_REQUEST";
const DELETE_TEMPLATE_IN_PROGRESS = "DELETE_TEMPLATE_IN_PROGRESS";
const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
const DELETE_TEMPLATE_FAILED = "DELETE_TEMPLATE_FAILED";

export {
  SAVE_FORM_REQUEST,
  SAVE_FORM_IN_PROGRESS,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILED,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_IN_PROGRESS,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  EDIT_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_IN_PROGRESS,
  EDIT_TEMPLATES_SUCCESS,
  EDIT_TEMPLATES_FAILED,
  CHANAGE_TEMPLATES_STATUS,
  CHANAGE_TEMPLATES_STATUS_IN_PROGRESS,
  CHANAGE_TEMPLATES_STATUS_SUCCESS,
  CHANAGE_TEMPLATES_STATUS_FAILED,
  GET_EDIT_TEMPLATE_REQUEST,
  GET_EDIT_TEMPLATE_IN_PROGRESS,
  GET_EDIT_TEMPLATE_SUCCESS,
  GET_EDIT_TEMPLATE_FAILED,
  DELETE_TEMPLATES_REQUEST,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILED,
};
