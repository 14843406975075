import { put, call, takeLatest } from "redux-saga/effects";
import NotificationHelper from "../../../helpers/NotificationHelper";
import apiHandler from "../../../middlewares/connectApi";
import {
  doLoginFailed,
  doLoginInProgress,
  doLoginSuccess,
} from "../action/loginAction";
import { DO_LOGIN_REQUEST } from "../constants";
export function* doLogin(data) {
  try {
   
    yield put(doLoginInProgress());

    const result = yield call(apiHandler.doLogin, data.payload);

    localStorage.setItem("token", result.data.token);

    yield put(doLoginSuccess(result.data.token));

    data.payload.navigates("/template/view?status=active")
   // window.location = "/template/view?status=active";
  } catch (error) {
    if (error.response.data.msg) {
      NotificationHelper.getInstance().error(error.response.data.msg);
    }

    yield put(doLoginFailed(error));
  }
}

export default function* loginSagas() {
  yield* [takeLatest(DO_LOGIN_REQUEST, doLogin)];
}
