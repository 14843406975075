import dev from "./dev";
import prod from "./prod";
import uat from "./uat";

const env = process.env.REACT_APP_STAGE;
const envConfig = env === "dev" ? dev : env === "prod" ? prod : env === "uat" ? uat: null;

export default {
  ...envConfig,
};
