module.exports = {
    config: {
      appName: "com_bank",
      env: "dev",
      api: {
        domains: {
          user: 'https://d2xur0ry1ztrxa.cloudfront.net',
        },
      },
    },
  };
