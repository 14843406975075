import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { ACTIVATE_TEMPLATE_MESSAGE, DEACTIVATE_TEMPLATE_MESSAGE, INACTIVE, PERMANETLY_DELETE_MESSAGE } from "../../constants/constants";

const DeActivatemodel = (props) => {
  const { isDeactivateModel, handleOnCancel, handleDeactivate,templateStatus,isDeleteTemplate,handleTemplateDelete} = props;

  return (
    <div>
      <Modal
        wrapClassName="deactivateModal"
        centered
        title="Basic Modal"
        visible={isDeactivateModel}
        onCancel={()=>{
            handleOnCancel();
        }}
      >
        <p className="deactivateText">
          { `Are you sure you want to ${isDeleteTemplate ? PERMANETLY_DELETE_MESSAGE :templateStatus === INACTIVE? ACTIVATE_TEMPLATE_MESSAGE:DEACTIVATE_TEMPLATE_MESSAGE}?`}
        </p>
        <div className="deactivateBtnSection">
          <Button className="noBtn" onClick={()=>{
              handleOnCancel()
          }}>
            No
          </Button>
          <Button className="yesBtn" onClick={()=>{
              isDeleteTemplate ? handleTemplateDelete() : handleDeactivate()
          }}>
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeActivatemodel;
