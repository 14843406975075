import axios from "axios";
import domain from "../config/index";
import authHeader from "../helpers/authHeader";
const { domains } = domain.config.api;
const { user: apiDomain } = domains;

const connectApi = {
  doLogin: (data) => {
    return axios.post(`${apiDomain}/api/login`, data);
  },

  saveForm: (data) => {
    return axios.post(`${apiDomain}/api/templates`, data, {
      headers: authHeader(),
    });
  },

  getViewTemplate: (data) => {
    return axios.get(
      `${apiDomain}/api/templates/views?type=${data.type}&status=${data.status}`,
      { headers: authHeader() }
    );
  },
  updateViewTemplate: (data) => {
    return axios.put(`${apiDomain}/api/templates/edit`, data, {
      headers: authHeader(),
    });
  },
  chanageStatusTemplate: (data) => {
    console.log("data",data);
    return axios.put(
      `${apiDomain}/api/templates/deactivate`,
      { idtemplates: data },
      { headers: authHeader() }
    );
  },
  getEditTemplate: (data) => {
    return axios.get(`${apiDomain}/api/template/edit?param=${data}`, {
      headers: authHeader(),
    });
  },

  deleteTemplate: (data) => {
    return axios.delete(
      `${apiDomain}/api/templates/delete`,
      { headers: authHeader(),data: { templateObj: data } },
    
    );
  },
};

export default connectApi;

