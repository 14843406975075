import { notification } from "antd";
import errorImage from "../assets/Images/error.png";
let _instance = null;

class NotificationHelper {
  state = {
    success: {
      show: false,
      message: "",
    },
    error: {
      show: false,
      message: "",
    },
    warning: {
      show: false,
      message: "",
    },
  };

  static getInstance() {
    if (_instance === null) {
      _instance = new NotificationHelper();
    }

    return _instance;
  }

  success(message) {
    notification["success"]({
      key: "success",
      message: message,
      className: "notificationBoxTwo",
    });
  }

  warning(message) {
    notification["warning"]({
      key: "warning",
      message: message,
    });
  }

  error(message) {
    notification["error"]({
      key: "error",
    //   duration:0,
      message: message,
      className: "notificationBox",
      placement: "bottomRight",
      icon: <img src={errorImage} className="errorImage" />,
    });
  }
}

export default NotificationHelper;
