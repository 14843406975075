module.exports = {
    config: {
      appName: "com_bank",
      env: "prod",
      api: {
        domains: {
         user: 'https://combankgreetings.com',
        },
      },
    },
  };
  