import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { EDIT_TEMPLATE_NAME } from "../../constants/constants";
const { TabPane } = Tabs;
const CommonNavlink = (props) => {
  const { templateData, chekboxType, handleChange } = props;
  const location = useLocation();
  const [activeKeyType, setActiveKeyType] = useState("");
  const [getParam] = useSearchParams();
  const paramStatus = getParam.get("status");

  useEffect(() => {
    setActiveKeyType(chekboxType);
    // if(chekboxType === "greeting") NotificationHelper.getInstance().success("greerting");
    // else  NotificationHelper.getInstance().success("prmotion");
  }, [chekboxType]);

  const templae_Map = {
    active: `/template/view?param=${chekboxType}&status=active`,
    inactive: `/template/view?param=${chekboxType}`,
    templates: `/template?param=${chekboxType}`,
  }
  return (
    <div>
      <Row>
        <Col xs={24 } sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            className="tabsSection"
            onChange={(e) => {
              handleChange(e);
            }}
            activeKey={activeKeyType}
          >
            <TabPane tab="Greetings" key={"greeting"}></TabPane>
            <TabPane tab="Promotions" key={"promotion"} >
              {" "}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <div className="templateLinkSection">
        <Link
          to={templae_Map.active}
          className="templateLink"
          state={"ACTIVE"}
          style={{
            color:
              paramStatus === "active" || location.state === "ACTIVE"
                ? "#1d56a3"
                : null,
            fontWeight:
              paramStatus === "active" || location.state === "ACTIVE"
                ? 700
                : null,
          }}
        >
          View Template
        </Link>

        <Link
          to={templae_Map.templates}
          className="templateLink"
          style={{
            color: location.pathname === "/template" ? "#1d56a3" : null,
            fontWeight: location.pathname === "/template" ? 700 : null,
          }}
        >
          {templateData ? EDIT_TEMPLATE_NAME : "Create"}{" "}Template
        </Link>
        <Link
          to={templae_Map.inactive}
          className="templateLink"
          state={"INACTIVE"}
          style={{
            color: location.state === "INACTIVE" ? "#1d56a3" : null,
            fontWeight: location.state === "INACTIVE" ? 700 : null,
          }}
        >
          View Deactivated Template
        </Link>
      </div>
    </div>
  );
};

export default CommonNavlink;
