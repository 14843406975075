import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect} from "react-redux";
import { Row, Col, Input, Button } from "antd";
import { doLogin } from "../action/loginAction";
import NotificationHelper from "../../../helpers/NotificationHelper";
import loginImgBackground from "../../../assets/Images/loginBackground.png";
import logoImgNew from "../../../assets/Images/ComBank Logo.jpg";

const Loginform = (props) => {
  let navigate = useNavigate();
  const { doLogin, loginStatus } = props;
  const [form, setForm] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setErrors(validation(form));
    setIsSubmit(true);
    if (loginStatus) {
      setLoginAttempts(loginAttempts + 1);
      if (loginAttempts === 4) {
        setIsLocked(true);
        setTimeout(() => {
          NotificationHelper.getInstance().error(
            "Too many incorrect attempts. Please try again later"
          );
        }, 700);

        setTimeout(() => {
          setLoginAttempts(0);
          setIsLocked(false);
        }, 60000);
      }
    }
  };

  const handleChange = (e) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      let _form = {
        ...form,
        navigates: navigate,
      };

      doLogin(_form);
    }
  }, [errors]);

  useEffect(() => {
    handleValidation();
  }, [form]);

  const validateEmail = (email) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const validation = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Please enter email";
    }

    if (!validateEmail(values.email)) {
      errors.email = "Invalid email Address";
    }

    if (!values.password) {
      errors.password = "Please enter password";
    }
    if (!validatePassword(values.password)) {
      errors.password =
        "password should contain atleast one number and one special character";
    }
    return errors;
  };

  const handleValidation = () => {
    if (form.email) {
      setErrors((prev) => {
        return { ...prev, email: "" };
      });
    }
    if (validateEmail(form.email)) {
      setErrors((prev) => {
        return { ...prev, email: "" };
      });
    }

    if (!form.password) {
      setErrors((prev) => {
        return { ...prev, password: "" };
      });
    }
  };

  return (
    <div className="loginContainer">
      <Row>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <div
            className="leftSectionLogin"
            style={{
              backgroundImage: `url(${loginImgBackground})`,
            }}
          >
            <div className="loginGreetingHeadingBox">
              <span className="greetingsHead">Greetings Application</span>
              <p className="loginText">
                This app enables the bank to send greetings to clients on their
                life celebrations , religious & cultural festivals and to send
                promotional advertisments.{" "}
              </p>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <div className="rightSectionLogin">
            <div className="logoSectionBox">
              <img src={logoImgNew} className="logoLogin" alt="logoNew" />
            </div>
            <div className="logoSectionMain">
              <div className="loginSectionBoxTwo">
                <div className="logoWithLogin">
                  <span className="headingLogin">Welcome Back!</span>
                  <span className="promoText">
                    Commercial Bank Greeting & Promo App
                  </span>
                </div>
                <form onSubmit={submit}>
                  <div className="inputSection">
                    <p className="email label">E-mail</p>
                    <Input
                      className="inputFeild "
                      name="email"
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <br />
                    <span className="errorMessage">{errors.email}</span>
                    <p className="password label">Password</p>
                    <Input.Password
                      className="inputFeild"
                      name="password"
                      type="password"
                      value={form.password}
                      onChange={handleChange}
                      style={{ color: "red" }}
                      autoComplete="off"
                    />
                    <br />
                    <span className="errorMessage">{errors.password}</span>
                  </div>
                  <Button
                    htmlType="submit"
                    className="continueBtn"
                    disabled={isLocked}
                  >
                    Sign in
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="uploadImage"></div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginReducer.state
      ? state.loginReducer.state.login_fail
      : undefined,
  };
};

const mapDispatchToProps = (dispatch) => ({
  doLogin: (payload) => {
    dispatch(doLogin(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Loginform);
