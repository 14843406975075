import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Images/ComBank LogoNewVersion.png";
import { Button } from "antd";
import domain from'../../config/index';
import { io } from "socket.io-client";
const { domains } = domain.config.api;
const { user: userDomain } = domains;
const Header = () => {
  const navigate = useNavigate();
  const socket = io(`${userDomain}`,{transports: ['polling','websocket'],path:'/api/socket.io/'})
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loggedin, setLoggedIn] = useState(true);

  useEffect(() => {
    if (token === null) {
      return navigate("/");
    }
  }, [token]);

  const LogOut = () => {
    
    let sessionId = localStorage.getItem("sessionId");
    socket.emit('disconnected',sessionId)
    localStorage.removeItem("token");
    localStorage.removeItem("sessionId");
    setToken(null);
   
  };

  //to check inactivity and logout
  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    console.log("expireTime->", expireTime);
    if (expireTime < Date.now()) {
    localStorage.removeItem("token");
    localStorage.removeItem("sessionId");
      setLoggedIn(false);
      navigate("/");
    }
  };

  //update expire time
  const updateExpireTime = () => {
    const expireTime = Date.now() + 10000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    //cleanup
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  return (
    <div className="headerContainer">
      {/* {loggedin.toString()}; */}
      <div className="headerMain">
        <img src={logo} className="logoImg" />
      </div>
      <div className="logOutMain">
        <Button className="logoutBtn" onClick={LogOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};

export default Header;
