import { call, put,takeLatest } from "redux-saga/effects";
import {
  getEditemplateInProgress,
  getEditTemplateSuccess,
  getViewTemplateFailed,
  getViewTemplateInProgress,
  getViewTemplateSuccess,
} from "../action/viewTemplateAction";
import { GET_EDIT_TEMPLATE_REQUEST, GET_TEMPLATES_REQUEST } from "../constants";
import apiHandler from "../../../middlewares/connectApi";

export function* getViewTemplate(data = null) {
  try {
    yield put(getViewTemplateInProgress());
   
    const result = yield call(apiHandler.getViewTemplate, data.payload);

    yield put(getViewTemplateSuccess(result.data));
    //   if(result){
    //       NotificationHelper.getInstance().success("sucess");
    //   }
  } catch (error) {
    // let {
    //     response: { data }
    // } = error;
    yield put(getViewTemplateFailed(error));
  }
}

export function* getEditTemplate(data) {
  try {
    yield put(getEditemplateInProgress());
    const result = yield call(apiHandler.getEditTemplate, data.payload);

    
    yield put(getEditTemplateSuccess(result.data));
  } catch (error) {
    // let {
    //     response: { data }
    // } = error;
    yield put(getViewTemplateFailed(error));
  }
}

export default function* viewTemplateSagas() {
  yield* [
    takeLatest(GET_TEMPLATES_REQUEST, getViewTemplate),
    takeLatest(GET_EDIT_TEMPLATE_REQUEST, getEditTemplate),
  ];
}
