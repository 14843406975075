import React, { useEffect, useState } from "react";
import { Button, Row, Col, Input } from "antd";
import uploadImg from "../../assets/Images/upload1.png";
import Viewlist from "./viewCard";
import NotificationHelper from "../../helpers/NotificationHelper";
import { GREETING, PROMOTION } from "../../constants/constants";
const Imageuploadtemplate = (props) => {
  const {
    handleForm,
    uplodedImage: _uplodedImage,
    validation_Message,
    isPromotion,
    isgreeting,
    setErrors,
handleUploadValidation
  } = props;
  
  const [iscardVisible, setIsCardVisible] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [uploadedImage, setUplodedImage] = useState();


  useEffect(() => {
    setUplodedImage(_uplodedImage);
  }, [_uplodedImage]);

  const MAXFILE_SIZE = 150000;
  const handleTemplate = (e) => {
    const FILE_SIZE = e.target.files[0]?.size;
    const FILE_TYPE = e.target.files[0]?.type;

    handleForm((pre) => {
      return { ...pre, templateImg: e.target.files[0] };
    });

    if (e.target.files[0]) {
      if (
        !(
          FILE_TYPE === "image/jpeg" ||
          FILE_TYPE === "image/jpg" ||
          FILE_TYPE === "image/png" 
        )
      ) {
        NotificationHelper.getInstance().error(
          "Upload Error.This image file format is incorrect"
        );
         handleUploadValidation(true)
      return;
      }
      if (FILE_SIZE > MAXFILE_SIZE) {
        NotificationHelper.getInstance().error(
          "Upload Error. This image exceeds the maximum upload size"
        );
        handleUploadValidation(true)
        return;
      }

      let image = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        image.src = e.target.result;

        image.onload = function () {
          let width = this.width;
          let height = this.height;
          if (isgreeting) {
            if (width !== 563 || height !== 400) {
              NotificationHelper.getInstance().error(
                "Upload Error. Please check the image dimensions"
              );
             handleUploadValidation(true)
              return false;
            }
          } else {
            if (width !== 400 || height !== 563) {
              NotificationHelper.getInstance().error(
                "Upload Error. Please check the image dimensions"
              );
              handleUploadValidation(true)
              return false;
            }
          }

          if (reader.readyState === 2) {
            setPreviewImage(reader.result);
            setErrors((prev) => {
              return { ...prev, templateImg: "" };
            });
          }
        };
      };
      reader.readAsDataURL(e.target.files[0]);

      setUplodedImage(null);
    }
  };

  const viewCardModel = () => {
    setIsCardVisible(true);
  };

  const handlecancel = () => {
    setIsCardVisible(false);
  };
  const handleFileUpload = () => {
    document.getElementById("selectFile").click();
  };
  // let Promo=true ;

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <span className="subHead">
            Upload {isPromotion ? PROMOTION : isgreeting ? GREETING : null}{" "}
            Design
          </span>
        </Col>
      </Row>
      <div
        className={
          isPromotion
            ? " imgUploadContainer imgUploadContainerPromotion"
            : "imgUploadContainer"
        }
      >
        <div className="uploadRightSection">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <div className="uploadRightSectionLeft">
                <p className="uploadImageLabel">
                  Upload Design <sup className="mandatory"> * </sup>
                </p>

                <Button
                  className={
                    isPromotion
                      ? "uploadTemplateBtn uploadTemplateBtnPromotion"
                      : "uploadTemplateBtn"
                  }
                  onClick={handleFileUpload}
                >
                  Choose File
                  <img src={uploadImg} className="uploadIcon" />
                </Button>

                {validation_Message === "Please upload a template" ? (
                  <span className="errorMessage">{validation_Message}</span>
                ) : null}
                <p className="fileFormatBox">
                  <span className="BestViewText">Best View Specification</span>
                  <br />
                  <span className="fileDetailsHead">File Size:</span>
                  <span className="fileDetailText">150KB or below </span>
                  <br />
                  <span className="fileDetailsHead">File Format:</span>{" "}
                  <span className="fileDetailText">JPG , PNG or JPEG</span>
                  <br />
                  {isPromotion ? (
                    <>
                      <span className="fileDetailsHead">Dimensiones:</span>
                      <span className="fileDetailText">
                        563 (Height) by 400 (Width) pixels
                      </span>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <span className="fileDetailsHead">Dimensiones:</span>
                      <span className="fileDetailText">
                        400 (Height) by 563 (Width) pixels
                      </span>
                    </>
                  )}
                </p>

                <Input
                  className="uploadInput"
                  type={"file"}
                  onChange={handleTemplate}
                  id="selectFile"
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="uploadImageCol"
            >
              {uploadedImage || previewImage ? (
                <div className="uploadRightSectionRight">
                  <div className="uploadViewImgSection">
                    <img
                      src={
                        uploadedImage
                          ? uploadedImage
                          : previewImage
                          ? previewImage
                          : null
                      }
                      alt="greetingImg"
                      className={
                        isPromotion ? "wishImg wishImgPromotion" : "wishImg"
                      }
                    />

                    <div
                      className={
                        isPromotion
                          ? "viewBtnSection viewBtnSectionPromotion"
                          : "viewBtnSection"
                      }
                    >
                      <Button className="viewBtn" onClick={viewCardModel}>
                        View
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>

      {iscardVisible && (
        <Viewlist
          isPromotionView={isPromotion}
          isPromotion={isPromotion}
          show={iscardVisible}
          handlecancel={handlecancel}
          previewImage={uploadedImage ? uploadedImage : previewImage}
        />
      )}
    </div>
  );
};

export default Imageuploadtemplate;
