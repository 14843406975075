 const DO_LOGIN_REQUEST = "DO_LOGIN_REQUEST";
 const DO_LOGIN_IN_PROGRESS = "DO_LOGIN_IN_PROGRESS";
 const DO_LOGIN_SUCCESS = "DO_LOGIN_SUCCESS";
 const DO_LOGIN_FAILED = "DO_LOGIN_FAILED";

export  {
  DO_LOGIN_REQUEST,
  DO_LOGIN_IN_PROGRESS,
  DO_LOGIN_SUCCESS,
  DO_LOGIN_FAILED,
};
