import React, { useState } from "react";
import { Row, Col, Input, Divider } from "antd";
import { NavLink } from "react-router-dom";
import { DatePicker, Popover } from "antd";
import moment from "moment";
import Viewlist from "./viewCard";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  changeTemplateStatus,
  deleteTemplate,
} from "../../containers/templates/action/templateFormAction";
import DeActivatemodel from "../modals/deactivateModal";

const dateFormatList = ["YYYY-MM-DD", "DD/MM/YYYY"];

const Commonviewcard = (props) => {
  const { templates, doChangeTemplateStatus, status, doDeleteTemplate, isPromotion } =
    props;

  const [iscardVisibleNew, setIsCardVisibleNew] = useState();
  const [isToollipVisible, setIsToollipVisible] = useState(false);
  const [templateStatus, setTemplateStatus] = useState(status);
  const [isDeleteTemplate, setIsDeleteTemplate] = useState(false);

  const viewCardModel = () => {
    setIsCardVisibleNew(true);
  };

  const [isDeactivateModel, setIsDeactivateModel] = useState(false);

  const showDeactivateModel = (e) => {
    if (e === "status") {
      setIsDeactivateModel(true);
      setIsDeleteTemplate(false);
    } else {
      setIsDeactivateModel(true);
      setIsDeleteTemplate(true);
    }
  };

  const handleOnCancel = () => {
    setIsDeactivateModel(false);
    setIsToollipVisible(false);
  };

  const handlecancel = () => {
    setIsCardVisibleNew(false);
  };

  const handleDeactivate = () => {
    const deactivateObj = {
      idtemplates: templates.idtemplates,
      type: templates.templateType,
      status: templateStatus,
    };

    doChangeTemplateStatus(deactivateObj);

    setIsDeactivateModel(false);

    // setIsToollipVisible(false)
  };

  const handleTemplateDelete = () => {
    //setIsDeleteTemplate(true)
    const deleteTemplateObj = {
      idtemplates: templates.idtemplates,
      type: templates.templateType,
      status: templateStatus,
    };
    doDeleteTemplate(deleteTemplateObj);
    setIsDeactivateModel(false);
  };

  // let Promo = true;

  const content = (
    <div className="popUpEdit">
      {templateStatus === "INACTIVE" ? (
        <>
          <a
            className="linksEdit"
            onClick={() => {
              showDeactivateModel("status");
            }}
          >
            {templateStatus === "INACTIVE" ? "Activate" : "Deactivate"}
          </a>
          <Divider className="divider" />
          <a
            className="linksEdit"
            onClick={() => {
              showDeactivateModel("delete");
            }}
          >
            Delete
          </a>
        </>
      ) : (
        <>
          <NavLink
            key={"template"}
            to={`/template?id=${templates.idtemplates}`}
            state={{ myState: templates }}
            className="linksEdit"
          >
            Edit
          </NavLink>
          <Divider className="divider" />

          <a
            className="linksEdit"
            onClick={() => {
              showDeactivateModel("status");
            }}
          >
            {templateStatus === "INACTIVE" ? "Active" : "Deactivate"}
          </a>
        </>
      )}
    </div>
  );

  return (
    <div className="viewCardContainer" style={{ height: isPromotion ? 248 : 213 }}>
      <Row gutter={[isPromotion ? 20 : 5, 0]}>
        <Col
          xs={24}
          sm={12}
          md={isPromotion ? 7   : 8}
          lg={isPromotion ? 9 : 10}
          xl={isPromotion ? 7 : 10}
          xxl={isPromotion ? 7 : 10}
        >
          <div
            className={
              isPromotion
                ? "viewCardLeftImageWrap viewCardLeftImageWrapPromotion"
                : "viewCardLeftImageWrap"
            }
          >
            <div
              style={{
                backgroundImage: `url(${templates.uploadImg})`,
              }}
              className={
                isPromotion ? "viewCardLeft viewCardLeftPromotion" : "viewCardLeft"
              }
            ></div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={isPromotion ? 17 : 16}
          lg={isPromotion ? 15 : 14}
          xl={isPromotion ? 17 : 14}
          xxl={isPromotion ? 17 : 14}
        >
          <div className="viewCardRight">
            <Row>
              <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                <div className="formTemplateTop">
                  <Row gutter={[isPromotion ? 20 : 15, 0]}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="templateFormLabel">Template Name</p>
                      <Input
                        className="inputFeildTemplate "
                        defaultValue={templates.templateName}
                        disabled
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="templateFormLabel">Subject Line</p>
                      <Input
                        className=" inputFeildTemplate"
                        defaultValue={templates.subjectLine}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="formTemplateBottom">
                  <Popover
                    placement="bottom"
                    content={content}
                    trigger={"hover"}
                    visible={isDeactivateModel ? false : isToollipVisible}
                    onVisibleChange={(e) => {
                      if (e) {
                        setIsToollipVisible(true);
                      } else {
                        setIsToollipVisible(false);
                      }
                    }}
                  >
                    <MoreOutlined
                      className={
                        isPromotion ? "dotsPopup dotsPopupPromotion" : "dotsPopup"
                      }
                    />
                  </Popover>
                  <Row gutter={[isPromotion ? 20 : 15, 0]}>
                    <Col xs={12} sm={24} md={12} lg={12} xl={12}>
                      <p className="templateFormLabel">Start Date</p>
                      <DatePicker
                        className="inputFeildTemplate"
                        value={moment(templates.startDate, dateFormatList[0])}
                        format={dateFormatList}
                        disabled
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <p className="templateFormLabel">End Date</p>
                      <DatePicker
                        className="inputFeildTemplate"
                        value={moment(templates.endDate, dateFormatList[0])}
                        format={dateFormatList}
                        disabled
                      />
                    </Col>
                  </Row>

                  <div className="viewLinkSection">
                    <a className="viewLink" onClick={viewCardModel}>
                      View
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {iscardVisibleNew && (
        <Viewlist
          isPromotionview={isPromotion}
          show={iscardVisibleNew}
          handlecancel={handlecancel}
          viewTemplate={templates.uploadImg}
        />
      )}
      {isDeactivateModel && (
        <DeActivatemodel
          isDeactivateModel={isDeactivateModel}
          handleOnCancel={handleOnCancel}
          handleDeactivate={handleDeactivate}
          templateStatus={templateStatus}
          handleTemplateDelete={handleTemplateDelete}
          isDeleteTemplate={isDeleteTemplate}
        />
      )}
    </div>
  );
};
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  doChangeTemplateStatus: (payload) => {
    dispatch(changeTemplateStatus(payload));
  },
  doDeleteTemplate: (payload) => {
    dispatch(deleteTemplate(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Commonviewcard);
