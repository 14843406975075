import React, { useEffect, useState } from "react";
import Commantemplate from "../../../components/common/templateForm";
import Header from "../../../components/header";
import { Row, Col, Spin } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import Footer from "../../../components/footer";
import { connect } from "react-redux";
import { editTemplateForm, saveForm } from "../action/templateFormAction";
import { EDIT_TEMPLATE } from "../../../constants/templateFormConstants";
import {
  editTemplates_inprograss,
  getEditTemplateSuccess,
  geteditTemplate_inProgress,
  saveForm_inProgress,
  templateForm_validation,
} from "../selectors/templateSelectors";
import { createStructuredSelector } from "reselect";
import { getEditTemplate } from "../action/viewTemplateAction";
import CommonNavlink from "../../../components/common/commonNavLink";
import { useNavigate } from "react-router-dom";

const TemplateForm = (props) => {
  const {
    doSaveForm,
    doEditTemplate,
    doGetEditTemplate,
    getEditTemplateSuccess: _responseData,
    editTemplates_inprograss,
    templateForm_validation,
    saveForm_inProgress,
    geteditTemplate_inProgress
  } = props;

  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getParam = searchParams.get("id");
  const location = useLocation();

  const [isPromotion, setisPromotion] = useState(false);
  const [isgreeting, setisgreeting] = useState(true);
  const [templateData, setTemplateData] = useState(
    location.state !== null ? location.state.myState : null
  );

  const [paramType, setParamType] = useState(
    searchParams ? searchParams.get("param") : null
  );

  useEffect(() => {
    if (_responseData && _responseData[0]?.templateType === "promotion") {
      setisgreeting(false);
      setisPromotion(true);
    }
    if (_responseData && _responseData[0]?.templateType === "greeting") {
      setisPromotion(false);
      setisgreeting(true);
    }
  }, [editTemplates_inprograss, _responseData]);

  useEffect(() => {
    if (paramType !== null) {
      if (paramType === "promotion") {
        setisgreeting(false);
        setisPromotion(true);
      } else if (paramType === "greeting") {
        setisPromotion(false);
        setisgreeting(true);
      } else {
        setParamType(null);
      }
    }
  }, [paramType]);
  useEffect(() => {
    if (getParam) doGetEditTemplate(getParam);
  }, [editTemplates_inprograss]);

  const submitTemplate = (form) => {
    
    var formdata = new FormData();
    formdata.append("templateName", form.templateName);
    formdata.append("startDate", form.startDate);
    formdata.append("subjectLine", form.subjectLine);
    formdata.append("endDate", form.endDate);
    formdata.append("img", form.templateImg);
    formdata.append("idtemplates", templateData?.idtemplates);
    
    isgreeting
      ? formdata.append("greeting", "greeting")
      : formdata.append("promotion", "promotion");

    const formObj = {
      formdata,
      templateType: isgreeting ? "greeting" : "promotion",
      navigates:navigate
    };
    if (templateData && EDIT_TEMPLATE.ACTIONS.EDIT === "edit") {
      const formObj = {
        formdata,
        templateType: isgreeting ? "greeting" : "promotion",
        navigates:navigate
      };

      doEditTemplate(formObj);
    } else doSaveForm(formObj);

    // for (var [key, value] of formdata.entries()) {
    
    // }
  };
  
  function onChange(type) {
    if (type === "greeting") {
      setisPromotion(false);
      setisgreeting(true);
    }
    if (type === "promotion") {
      setisgreeting(false);
      setisPromotion(true);
    }
  }

  return (
    <div className="templateMainSection">
      <Header />

{!geteditTemplate_inProgress ? 
      <div className="templateBodyContainer">
        <div className="middleSection">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="sectionOneRight"
            >
              <CommonNavlink
                templateData={templateData ? templateData : null}
                chekboxType={isPromotion ? "promotion" : "greeting"}
                handleChange={onChange}
              />
            </Col>
          </Row>
        </div>
        <div className="commonSectionAdmin">
          <Commantemplate
            handleSubmit={submitTemplate}
            templateData={templateData && _responseData ? _responseData[0] : []}
            editTemplates_inprograss={editTemplates_inprograss}
            validation_Message={templateData ? false : templateForm_validation}
            saveForm_inProgress={saveForm_inProgress}
            isPromotion={isPromotion}
            isgreeting={isgreeting}
          />
        </div>
      </div>

:  <Spin size="large" className="spin"  /> }
      <Footer />
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  templateForm_validation: templateForm_validation(),
  getEditTemplateSuccess: getEditTemplateSuccess(),
  editTemplates_inprograss: editTemplates_inprograss(),
  saveForm_inProgress: saveForm_inProgress(),
  geteditTemplate_inProgress:geteditTemplate_inProgress()
});

const mapDispatchToProps = (dispatch) => ({
  doSaveForm: (payload) => {
    dispatch(saveForm(payload));
  },
  doEditTemplate: (payload) => {
    dispatch(editTemplateForm(payload));
  },
  doGetEditTemplate: (payload) => {
    dispatch(getEditTemplate(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm);
