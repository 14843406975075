import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {  Button, Row, Col, List, Spin } from "antd";
import CommonViewCard from "../../../components/common/commonViewCard";
import { connect } from "react-redux";
import { getViewTemplate } from "../action/viewTemplateAction";
import {
  deleteTemplate_inProgress,
  getviewTemplate_data,
  getviewTemplate_inProgress,
} from "../selectors/templateSelectors";
import { createStructuredSelector } from "reselect";
import CommonNavlink from "../../../components/common/commonNavLink";
import dataImg from "../../../assets/Images/noData.png";
import { useLocation, useSearchParams } from "react-router-dom";

const TemplateView = (props) => {
  const {
    doGetViewTemplates,
    getviewTemplate_data,
    getviewTemplate_inProgress,
  } = props;
  const [getParam] = useSearchParams();
  const location = useLocation();

  const [isPromotion, setisPromotion] = useState(false);
  const [isgreeting, setisgreeting] = useState(true);
  const [paramType, setParamType] = useState(
    getParam ? getParam.get("param") : null
  );

  const geTemplateView = useCallback(() => {
    const _obj = {
      type:
        paramType !== null ? paramType : isPromotion ? "promotion" : "greeting",
      status: location?.state,
    };
    doGetViewTemplates(_obj);
  }, [paramType, isPromotion, isgreeting, location?.state]);

  useEffect(() => {
    geTemplateView();
  }, [geTemplateView]);
  useEffect(() => {
    if (paramType !== null) {
      if (paramType === "promotion") {
        setisgreeting(false);

        setisPromotion(true);
      } else if (paramType === "greeting") {
        setisPromotion(false);
        setisgreeting(true);
      } else {
        setParamType(null);
      }
    }
  }, [paramType]);

  function onChange(type) {
    if (paramType) setParamType(null);
    if (type === "greeting") {
      setisPromotion(false);
      setisgreeting(true);
      
    }
    if (type === "promotion") {
      setisgreeting(false);
      setisPromotion(true);
    }
  }

  return (
    <div className="templateViewMainContainer">
      <Header />
      {!getviewTemplate_inProgress ? (
        <div className="templateViewBodyContainer">
          <div className="middleSection">
            <Row gutter={[30, 0]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="sectionTwo"
              >
                <CommonNavlink
                  chekboxType={isPromotion ? "promotion" : "greeting"}
                  handleChange={onChange}
                />
              </Col>
            </Row>
          </div>

          {getviewTemplate_data && !getviewTemplate_data.length ? (
            <div className="noDataTextBox">
              <img src={dataImg} className="noDataImg" />
              <p className="oopsText">Opps! Seems you found nothing here.</p>
            </div>
          ) : (
            <List
              grid={{
                gutter: [30, 30],
                Coloumn: 2,
                xxl:2,
                xl: 2,
                lg: 2,
                md: 1,
                sm:1,
              }}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 6,
                total: getviewTemplate_data
                  ? getviewTemplate_data.length
                  : null,
              }}
              dataSource={getviewTemplate_data ? getviewTemplate_data : []}
              renderItem={(data) => (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <CommonViewCard templates={data} key={data.idtemplates} status = {location?.state} isPromotion={isPromotion}/>
                </Col>
              )}
            ></List>
          )}

          {/* <div className="viewListBtnSection">
            <Button className="backBtn" disabled>
              Back{" "}
            </Button>
            <Button className="nextBtn" disabled>
              Next
            </Button>
          </div> */}
        </div>
      ) : (
        <>
          <Spin size="large" className="spin" />
        </>
      )}
      <Footer className="viewFooter" />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  getviewTemplate_data: getviewTemplate_data(),
  deleteTemplate_inProgress: deleteTemplate_inProgress(),
  getviewTemplate_inProgress: getviewTemplate_inProgress(),
});
const mapDispatchToProps = (dispatch) => ({
  doGetViewTemplates: (payload) => {
    dispatch(getViewTemplate(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateView);
