import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import TemplateForm from "../containers/templates/templateForm";
import TemplateView from "../containers/templates/templateView";
import Login from "../containers/user/forms/loginForm";
import { useSelector } from "react-redux";
import domain from "../config/index";
import { io } from "socket.io-client";
const { domains } = domain.config.api;
const { user: userDomain } = domains;
const UserAuth = () => {
  const token = useSelector((state) => state.loginReducer.loggedUser);
  const isAuth = true;
  if (token !== null) return isAuth;

  return false;
};
export const ProtectedRoutes = () => {
  const loggedUserToken = useSelector((state) => state.loginReducer.loggedUser);
  const isAuth = UserAuth();
  const socket = io(`${userDomain}`,{transports: ['polling','websocket'],path:'/api/socket.io/'})
  let navigate = useNavigate();
  socket.io.on("reconnect_error", (error) => {
    // ...
    console.log("err",error);
  });
 console.log(socket);
 console.log(isAuth);
  useEffect(() => {
    
     
      socket.on('connection', () => {
        console.log('a user connected');
      });
      socket.emit("logged",loggedUserToken);
      socket.on("user",(users)=>  {
       if(isAuth){
       
        let socketID = localStorage.getItem("sessionId");
        let token =  localStorage.getItem("token");


        if(token){

          if(socketID){
            console.log("currentID",socketID);
           let  sessionExpire = users.loggedUser.filter(f =>  f.id === socketID);
           console.log("sessionExpire",sessionExpire);
    
           if(sessionExpire.length){
           
              let sessionId = localStorage.getItem("sessionId");
               socket.emit('disconnected',sessionId)
           
               localStorage.removeItem("token");
               localStorage.removeItem("sessionId");
                //navigate(`/`)
                window.location ='/'
           
           }
          }else {
            localStorage.setItem("sessionId",users.id);
          }
           
        }else{

          
          localStorage.removeItem("token");
          localStorage.removeItem("sessionId");
        }
       
      

       }
       
      })

   
  
  }, [isAuth]);
 

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

const routes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/template" element={<TemplateForm />} />
            <Route path="/template/view" element={<TemplateView />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};


export default routes;
