import {
  GET_EDIT_TEMPLATE_FAILED,
  GET_EDIT_TEMPLATE_IN_PROGRESS,
  GET_EDIT_TEMPLATE_REQUEST,
  GET_EDIT_TEMPLATE_SUCCESS,
  GET_TEMPLATES_FAILED,
  GET_TEMPLATES_IN_PROGRESS,
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
} from "../constants";

const initialState = {
  getviewTemplate_inital: null,
  getviewTemplate_inProgress: false,
  getviewTemplate_data: null,
  getviewTemplate_error: null,

  geteditTemplate_inProgress: false,
  geteditTemplate_data: null,
  geteditTemplate_error: null,
};

const viewTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_REQUEST:
      return { ...state, getviewTemplate_inital: null };
    case GET_TEMPLATES_IN_PROGRESS:
      return { ...state, getviewTemplate_inProgress: true };
    case GET_TEMPLATES_SUCCESS: 
      return {...state,getviewTemplate_data: action.result,getviewTemplate_inProgress: false};
    case GET_TEMPLATES_FAILED:
      return {...state, getviewTemplate_error: action.error };
    case GET_EDIT_TEMPLATE_REQUEST:
      return { ...state, getviewTemplate_data: null };
    case GET_EDIT_TEMPLATE_IN_PROGRESS:
      return { ...state, geteditTemplate_inProgress: true };
    case GET_EDIT_TEMPLATE_SUCCESS:
      return {...state,geteditTemplate_data: action.result,geteditTemplate_inProgress: false};
    case GET_EDIT_TEMPLATE_FAILED:
      return {...state, geteditTemplate_error: action.error };
    default:
      return state;
  }
};

export default viewTemplateReducer;
