import {
  SAVE_FORM_REQUEST,
  SAVE_FORM_IN_PROGRESS,
  SAVE_FORM_SUCCESS,
  SAVE_FORM_FAILED,
  EDIT_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_IN_PROGRESS,
  EDIT_TEMPLATES_SUCCESS,
  EDIT_TEMPLATES_FAILED,
  CHANAGE_TEMPLATES_STATUS,
  CHANAGE_TEMPLATES_STATUS_IN_PROGRESS,
  CHANAGE_TEMPLATES_STATUS_SUCCESS,
  CHANAGE_TEMPLATES_STATUS_FAILED,
  DELETE_TEMPLATE_IN_PROGRESS,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILED,
} from "../constants";

const initialState = {
  saveForm_data: null,
  saveForm_inProgress: false,
  saveForm_error: null,

  editTemplate_inProgress: false,
  editTemplate_data: null,
  editTemplate_error: null,

 changeTemplateStatus_inProgress: false,
 changeTemplateStatus_data: null,
 changeTemplateStatus_error: null,


  deleteTemplate_inital : null,
  deleteTemplate_inProgress: false,
  deleteTemplates_data: null,
  deleteTemplates_error: null,
};

const templateFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FORM_REQUEST:
      return { ...state, saveForm_data: null };
    case SAVE_FORM_IN_PROGRESS:
      return { ...state, saveForm_inProgress: true };
    case SAVE_FORM_SUCCESS:
      return { ...state, saveForm_data: action.result,saveForm_inProgress: false  };
    case SAVE_FORM_FAILED:
      return { state, saveForm_error: action.error };
    case EDIT_TEMPLATES_REQUEST:
      return { ...state, editTemplate_data: null };
    case EDIT_TEMPLATES_IN_PROGRESS:
      return { ...state, editTemplate_inProgress: true };
    case EDIT_TEMPLATES_SUCCESS:
      return { ...state, editTemplate_data: action.result,editTemplate_inProgress: false  };
    case EDIT_TEMPLATES_FAILED:
      return { ...state, editTemplate_error: action.error };
    case CHANAGE_TEMPLATES_STATUS:
      return { ...state, editTemplate_data: null };
    case CHANAGE_TEMPLATES_STATUS_IN_PROGRESS:
      return { ...state,changeTemplateStatus_inProgress: true };
    case CHANAGE_TEMPLATES_STATUS_SUCCESS:
      return { ...state,changeTemplateStatus_data: action.result,deleteTemplate_inProgress: false  };
    case CHANAGE_TEMPLATES_STATUS_FAILED:
      return { ...state,changeTemplateStatus_error: action.error };
    case DELETE_TEMPLATE_IN_PROGRESS:
      return { ...state, deleteTemplate_inProgress: true };
    case DELETE_TEMPLATE_SUCCESS:
      return { ...state, deleteTemplate_data: action.result ,deleteTemplates_inProgress: false };
    case DELETE_TEMPLATE_FAILED:
      return { ...state, deleteTemplate_error: action.error };     
    default:
      return state;
  }
};

export default templateFormReducer;
