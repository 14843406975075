import { put, call, takeLatest } from "redux-saga/effects";
import NotificationHelper from "../../../helpers/NotificationHelper";
import apiHandler from "../../../middlewares/connectApi";
import {
  changeTemplateStatusFailed,
  changeTemplateStatusInProgress,
  changeTemplateStatusSuccess,
  deleteTemplateFaild,
  deleteTemplateInProgress,
  deleteTemplateSuccess,
  editTemplateFormInProgress,
  saveFormFailed,
  saveFormInProgress,
  saveFormSuccess,
} from "../action/templateFormAction";
import { getViewTemplate as gtv } from "../action/viewTemplateAction";
import {
  CHANAGE_TEMPLATES_STATUS,
  DELETE_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_REQUEST,
  SAVE_FORM_REQUEST,
} from "../constants";

export function* saveForm(data) {
  try {
    const getState = data.payload.templateType;
    yield put(saveFormInProgress());
    const result = yield call(apiHandler.saveForm, data.payload.formdata);

    yield put(saveFormSuccess(result));
  
    if (result) {
      NotificationHelper.getInstance().success("Template created successfully");
     data.payload.navigates(`view?param=${getState}&status=active`)
     //window.location = `template/view?param=${getState}&status=active`;
    }
  } catch (error) {
    if (error.response.data.msg) {
      yield put(saveFormFailed(error.response.data.msg));
      NotificationHelper.getInstance().error(error.response.data.msg);
    } else yield put(saveFormFailed(error));
  }
}

export function* editTemplateForm(data) {
  try {
    const getState = data.payload.templateType;
    yield put(editTemplateFormInProgress());
    const result = yield call(
      apiHandler.updateViewTemplate,
      data.payload.formdata
    );
    yield put(saveFormSuccess(result));
    if (result) {
      NotificationHelper.getInstance().success("Template updated");
      data.payload.navigates(`view?param=${getState}&status=active`)
      //window.location = `template/view?param=${getState}&status=active`;
    }
  } catch (error) {
    if (error.response.data.msg) {
      NotificationHelper.getInstance().error(error.response.data.msg);
    }
    yield put(saveFormFailed(error));
  
  }
}

export function* changeTemplateStatus(data) {
  try {
    yield put(changeTemplateStatusInProgress());
    const result = yield call(apiHandler.chanageStatusTemplate, data.payload);
    yield put(changeTemplateStatusSuccess(result));
    if (result) {
      yield put(gtv(data.payload));
      if (data.payload.status === "ACTIVE") console.log(data.payload.status);
      NotificationHelper.getInstance().success(
        "Your template was successfully deactivated!"
      );
      if (data.payload.status === "INACTIVE")
        NotificationHelper.getInstance().success(
          "Your template was successfully activated!"
        );
    }
  } catch (error) {
    if (error.response.data.msg) {
      NotificationHelper.getInstance().error(error.response.data.msg);
    }
    yield put(changeTemplateStatusFailed(error));
    
  }
}
export function* deleteTemplate(data) {
  try {
  
    yield put(deleteTemplateInProgress());
    const result = yield call(apiHandler.deleteTemplate, data.payload);

    yield put(deleteTemplateSuccess(result));

    if (result) {
      yield put(gtv(data.payload));
      NotificationHelper.getInstance().success(
        "Your template was successfully deleted!"
      );
    }
  } catch (error) {
    if (error.response.data.msg) {
      NotificationHelper.getInstance().error(error.response.data.msg);
    }
    yield put(deleteTemplateFaild(error));
  }
}

export default function* formTemplateSagas() {
  yield* [
    takeLatest(SAVE_FORM_REQUEST, saveForm),
    takeLatest(EDIT_TEMPLATES_REQUEST, editTemplateForm),
    takeLatest(CHANAGE_TEMPLATES_STATUS, changeTemplateStatus),
    takeLatest(DELETE_TEMPLATES_REQUEST, deleteTemplate),
  ];
}
