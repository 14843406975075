//Template Form

export const GREETING = 'Greeting';
export const PROMOTION = 'Promotion';
export const EDIT_TEMPLATE_NAME = 'Edit';
export const INACTIVE ="INACTIVE";

export const PERMANETLY_DELETE_MESSAGE = `permanently delete the template. This cannot be undone`;
export const ACTIVATE_TEMPLATE_MESSAGE = `activate the template`;
export const DEACTIVATE_TEMPLATE_MESSAGE = `deactivate the template`;
