import { createSelector } from "reselect";

const viewTemplateReducer = (state) => state.viewTemplateReducer;
const templateFormReducer = (state) => state.templateFormReducer;

const getviewTemplate_data = () =>
  createSelector(
    viewTemplateReducer,
    (currentState) => currentState.getviewTemplate_data
  );

const getviewTemplate_initalData = () =>
  createSelector(
    viewTemplateReducer,
    (currentState) => currentState.getviewTemplate_inital
  );

  const getviewTemplate_inProgress = () =>
  createSelector(
    viewTemplateReducer,
    (currentState) => currentState.getviewTemplate_inProgress
  );

const geteditTemplate_inProgress = () =>
  createSelector(
    viewTemplateReducer,
    (currentState) => currentState.geteditTemplate_inProgress
  );

const getEditTemplateSuccess = () =>
  createSelector(
    viewTemplateReducer,
    (currentState) => currentState.geteditTemplate_data
  );

const editTemplates_inprograss = () =>
  createSelector(
    templateFormReducer,
    (currentState) => currentState.editTemplate_inProgress
  );

const templateForm_validation = () =>
  createSelector(
    templateFormReducer,
    (currentState) => currentState.saveForm_error
  );

const saveForm_inProgress = () =>
  createSelector(
    templateFormReducer,
    (currentState) => currentState.saveForm_inProgress
  );

const deleteTemplate_inProgress = () =>
  createSelector(
    templateFormReducer,
    (currentState) => currentState.deleteTemplate_inProgress
  );
export {
  getviewTemplate_data,
  templateForm_validation,
  getEditTemplateSuccess,
  geteditTemplate_inProgress,
  editTemplates_inprograss,
  getviewTemplate_initalData,
  saveForm_inProgress,
  deleteTemplate_inProgress,
  getviewTemplate_inProgress
};
