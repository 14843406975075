module.exports = {
  config: {
    appName: "com_bank",
    env: "dev",
    api: {
      domains: {
       //user: 'http://18.138.141.75',
        user: `http://localhost:4000`,
      },
    },
  },
};
console.log("new",process.env.REACT_APP_STAGE);


